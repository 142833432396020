<template>
    <div class="p-4 pt-10 md:pt-16">
        <div class="bg-white border border-innerBorder rounded-2xl p-4 lg:p-8 md:w-2/3 xl:w-1/2 mx-auto">
            <p class="font-bold text-2xl mb-6">Connexion</p>
            <LoginForm/>  
        </div>
        <div class="my-4 px-2 font-bold text-sm sm:text-base text-center">
            <router-link to="/pwdReset" class="text-blueLink underline">Mot de passe oublié ?</router-link>
            <p>Pas encore de compte ? <router-link to="/register" class="text-blueLink underline">Créer un compte</router-link></p>
        </div>
    </div>
</template>

<script>
import LoginForm from './forms/LoginForm'
export default {
    data() {
        return {
            
        }
    },
    components: {
        LoginForm
    },
    computed: {
        
    },
    methods: {
        
    },
}
</script>

<style scoped>

</style>